import React, { Component } from "react"
import Img from "react-image"
import VisibilitySensor from "react-visibility-sensor"
import axios from "axios"
import NumberFormat from "react-number-format"
import API_URL from "../../resources/api"
import Button from "react-bootstrap/Button"
class ResultItem extends Component {
  render() {
    let game = this.props.item
    console.log(game)
    return (
      <tr className="gamekey">
        <td>
          {game.image && (
            <VisibilitySensor>
              <Img
                src={[
                  game.image,
                  "https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg",
                ]}
                crossOrigin="anonymous"
              />
            </VisibilitySensor>
          )}
        </td>
        <td>{game.productsName}</td>
        <td>{game.keyseller}</td>
        <td>
          <NumberFormat
            value={game.productsPrice}
            displayType={"text"}
            decimalSeparator=","
            thousandSeparator="."
            prefix="EUR "
          />
        </td>

        <td>
          <NumberFormat
            value={game.difference}
            displayType={"text"}
            decimalSeparator=","
            thousandSeparator="."
            decimalScale="2"
            suffix="%"
          />
        </td>

        <td>
          <Button
            variant="success"
            onClick={() => {
              this.trackShopclick(game)
            }}
            block
            href={game.products_url}
          >
            <strong>Zum Shop</strong>
          </Button>
        </td>
      </tr>
    )
  }

  trackShopclick(gamekey) {
    console.log("Game= " + gamekey._id)
    //let url = "http://localhost:8080/api/shopClick/"
    let url = `${API_URL}/shopClick/`
    const options = {
      headers: { "Content-Type": "application/json" },
    }
    axios.post(url, gamekey, options).then(res => {
      console.log(res)
    })
  }
}

export default ResultItem
