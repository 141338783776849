import React from "react"
import { Link } from "gatsby"
import KeysellerMenu from "./keysellermenu"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"

const CustomNavbar = ({ pageInfo }) => {
  console.log(pageInfo)
  return (
    <Navbar variant="dark" expand="lg" id="site-navbar">
      {/* <Container> */}
      <Link to="/" className="link-no-style">
        <Navbar.Brand as="span">Gamekeyvergleich</Navbar.Brand>
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
          <KeysellerMenu />
        </Nav>
      </Navbar.Collapse>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
          <NavDropdown title="Topseller und Angebote" id="basic-nav-dropdown">
            <NavDropdown.Item href="/topseller" key="allkeyseller">
              Steam Topseller
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/charts">Spielecharts</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
      {/* </Container> */}
    </Navbar>
  )
}

export default CustomNavbar
