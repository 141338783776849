import React, { Fragment } from "react"
import { StaticQuery, graphql } from "gatsby"
import { NavDropdown } from "react-bootstrap"
import { Link } from "gatsby"

export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        allKeysellerJson(sort: { fields: title }) {
          nodes {
            title
            slug
          }
        }
      }
    `}
    render={data => <KeysellerMenu data={data} />}
  />
)
const KeysellerMenu = ({ data }) => {
  console.log(data)
  let keyseller = data.allKeysellerJson
  return (
    <Fragment>
      <NavDropdown title="Keyseller" id="basic-nav-dropdown">
        <NavDropdown.Item href="/keyseller" key="allkeyseller">
          <Link to="/keyseller/" className="link-no-style">
            <strong>Alle Keyseller</strong>
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Divider />
        {keyseller.nodes.map(item => (
          <NavDropdown.Item href={item.slug} key={item.slug}>
            <Link to={"/keyseller/" + item.slug} className="link-no-style">
              {item.title}
            </Link>
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    </Fragment>
  )
}
